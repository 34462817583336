import Aos from "aos";
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/common/loader";
import 'react-calendar/dist/Calendar.css';

import { UserContext } from "../context/context";
import Footer from "./footer";
import Header from "./header";

const Home = lazy(() => import("../pages/home"));
const Blog = lazy(() => import("../pages/blog"));
const Works = lazy(() => import("../pages/works"));
// const WorkCard = lazy(() => import("../pages/workCard"));
const Advantage = lazy(() => import("../pages/advantage"));
const BlogView = lazy(() => import("../pages/blogResult"));
const Cart = lazy(() => import("../pages/cart"));
const Checkout = lazy(() => import("../pages/checkout"));
const Servies = lazy(() => import("../pages/services"));
const ServiceView = lazy(() => import("../pages/servieView"));
const RecentBlog = lazy(() => import("../pages/recentBlog"));
const Story = lazy(() => import("../pages/story"));
const EventDetail = lazy(() => import("../pages/eventDetail"));
const SigninNumber = lazy(() => import("../pages/signinNumber"));
const SigninOtp = lazy(() => import("../pages/signinOtp"));
const CreateAccount = lazy(() => import("../pages/createAccount"));
const Profile = lazy(() => import("../pages/profile"));
const HelpDesk = lazy(() => import("../pages/helpDesk"));
const RaiseTicket = lazy(() => import("../pages/raiseTicket"));
const HelpDeskForm = lazy(() => import("../pages/helpDeskForm"));
const MyBooking = lazy(() => import("../pages/myBooking"));
const MyBookingView = lazy(() => import("../pages/myBookingView"));
const MyReferral = lazy(() => import("../pages/myReferral"));
const Notification = lazy(() => import("../pages/notification"));
const Conditions = lazy(() => import("../pages/conditions"));
const Failed = lazy(() => import("../components/Failed"));
const Success = lazy(() => import("../components/Success"));

const PrivacyPolicy = lazy(() => import("../pages/privacy"));
const Cancellation = lazy(() => import("../pages/refundsCancellation"));

const NotFound = lazy(() => import("../components/common/notFount"));


const CustomServies = lazy(() => import("../pages/customServies"));
const CustomServiesView = lazy(() => import("../pages/customServiesView"));
const CommunityServies = lazy(() => import("../pages/communityServices"));

export default function Layout() {
  const [currentPath, setCurrentPath] = useState("");
  const { pathname } = useLocation();
  const { language } = useContext(UserContext);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: true,
  //       includedLanguages:  'hi',
  //       // layout: google.translate.TranslateElement.InlineLayout.SIMPLE
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   // window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  // // useEffect(() => {
  // //   window.googleTranslateElementInit = googleTranslateElementInit;
  // // }, [language]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <div className="main_app">
        <Header />
        <Loader />
        <div className="pageBodywrp">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SigninNumber />} />
            <Route path="/signinotp" element={<SigninOtp />} />
            <Route path="/createaccount" element={<CreateAccount />} />
            <Route path="/puja-service" element={<Servies />} />
            <Route path="/puja-service/:category" element={<Servies />} />
            <Route path="/custom/services" element={<CustomServies />} />
            <Route path="/puja-service/:category/:service/:language" element={<ServiceView />} />
            <Route path="/puja-service/:category/:service" element={<Navigate to="/puja-service" replace />} />
            <Route path="/community-event" element={<CommunityServies />} />
            <Route path="/community-event/:service/:language" element={<ServiceView />}/>
            <Route path="/community-event/:service/:language" element={<Navigate to="/community-event" replace />}/>
            <Route path="/custom/services/view" element={<CustomServiesView />} />
            {/* <Route path="/event-detail/:id" element={<EventDetail />} /> */}
            <Route path="/event-detail/:slug/:language" element={<EventDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/mybooking" element={<MyBooking />} />
            <Route path="/mybooking/:id" element={<MyBookingView />} />
            <Route path="/myreferral" element={<MyReferral />} />
            <Route path="/helpdesk" element={<HelpDesk />} />
            <Route path="/raiseticket" element={<RaiseTicket />} />
            <Route path="/helpdeskform/:id" element={<HelpDeskForm />} />
            <Route path="/notifications" element={<Notification />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog/:slug" element={<RecentBlog />} />
            <Route path="/blog/view" element={<BlogView />} />
            <Route path="/the-samskara-story" element={<Story />} />
            <Route path="/advantage-samskara" element={<Advantage />} />
            <Route path="/how-it-works" element={<Works />} />
            <Route path="/how-it-works/:id" element={<Works />} />
            {/* <Route path="/how-it-works/:id" element={<WorkCard />} /> */}
            <Route path="/terms-and-conditions" element={<Conditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cancellation-policy" element={<Cancellation />} />
            <Route path="/app-success" element={<Success />} />
            <Route path="/app-cancel" element={<Failed />} />
            <Route path="*" element={<NotFound />} />


            {/* <Route path="/homeSearch" element={<HomeSearch />} /> */}
            {/* <Route path="/homeSearchCard" element={<HomeSearchCard />} /> */}
            {/* <Route path="/mediaCorner" element={<MediaCorner />} /> */}
            {/* <Route path="/panditInfo" element={<PanditInfo />} /> */}
            {/* <Route
              path="/trackProgressPanditDetails"
              element={<TrackProgressPanditDetails />}
            /> */}
            {/* <Route path="/bookingFeedBack" element={<BookingFeedBack />} /> */}
            {/* <Route path="/bookingMeeting" element={<BookingMeeting />} /> */}
            {/* <Route path="/address" element={<Address />} /> */}
            {/* <Route path="/reschedule" element={<Reschedule />} /> */}
            {/* <Route path="/cancilBooking" element={<CancilBooking />} /> */}
            {/* <Route path="/selectCountry" element={<SelectCountry />} /> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}
