
// import './App.css';
import bootstrap from 'bootstrap'
import { UserContext, UserProvider } from './context/context';
import { useEffect, useState } from 'react';
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from 'react-router-dom';
import Layout from './layout/layout';
import 'react-toastify/dist/ReactToastify.css'
import { HelmetProvider } from 'react-helmet-async';
// import "quill/dist/quill.snow.css";

import Aos from 'aos';

Aos.init();
function App() {
  const [isLogin, setLogin] = useState(false);
  const reset = () => {
    setLogin(false);
  }

  const helmetContext = {};




  return (
    <>
      <BrowserRouter>
        <UserProvider>
          <HelmetProvider context={helmetContext}>
            <ToastContainer />
            <Layout />
          </HelmetProvider>
        </UserProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
