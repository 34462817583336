import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../formik/errorMessage";
import ROOT_URL from '../../services/baseURL';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import { toast } from "react-toastify";

const Resehdule = () => {

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            code: '+91',

        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter name'),
            phone: Yup.string().required('Please enter phone').min(6, "number is too short!")
            .max(15, "number is too long!").matches(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,"Please enter only number"),
            code: Yup.string().required('Please enter code')
        }),

        onSubmit: values => {
            submitContact(values)
        }
    });

    const submitContact = (values) => {
        authAxios({
            method: "POST",
            url: `${ROOT_URL}/schedule/addRequest`,
            data: values
        }).then((res) => {
            toast.success(res.data.message);
            formik.resetForm()
        }).catch((error) => {
            common.error(error);
        })
    }
    return (
        <>
            <div className="tabContent ">
                <form onSubmit={formik.handleSubmit} autocomplete="off">
                    <div className="homenotiinputCon ">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="homenoticallbackcon">Schedule a Callback</div>
                                <div className="homeinptabcon">
                                    <input type="text" className="form-control" placeholder="Enter Name"  {...formik.getFieldProps('name')} />
                                    <ErrorMessage formik={formik} name="name" />
                                </div>
                            </div>
                        </div>

                        <div className="homeinputselectcoN">
                            <div className="row">
                                <div className="col-lg-4 col-5">
                                    <div className="homeselectinfocon">
                                        <select className="form-control" {...formik.getFieldProps('code')}>
                                            <option value="+91">+91</option>
                                            <option value="+65">+65</option>
                                        </select>
                                        <ErrorMessage formik={formik} name="code" />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-7">
                                    <div className="homeinptabWRP schedule">
                                        <input className="form-control" type="text" placeholder="Enter Mobile Number" {...formik.getFieldProps('phone')} />
                                        <ErrorMessage formik={formik} name="phone" />
                                    </div>
                                </div>
                                <div className="homeinfosubmitcon number">
                                    <div className="homeinfosubmittext"><button type="submit"> Submit</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Resehdule