import React, { useContext, useEffect, useState } from 'react'
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';
import { Link } from "react-router-dom";
import { UserContext } from '../../context/context';

const SelectMultiple = (props) => {
    const { initialData } = useContext(UserContext)
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])
    const { value, onBlur, onChange, remove } = props;

    const getTags = (e) => {
        let postData = {
            searchString: e || '',
            cityId: initialData?.city?._id || "",
            panditPreferenceId: initialData?.preference?._id || ""
        }


        return UnauthAxios({
            method: "POST",
            url: `/service/searchServices`,
            data: postData
        }).then((res) => {
            let resData = res?.data?.result;
            resData = resData.map((data) => {
                let firRes = {
                    label: data?.name?.replace(/(<([^>]+)>)/ig, ''),
                    value: data
                };
                return firRes
            })
            setOptions(resData);
            return resData;
        }).catch((error) => {
            common.error(error);
        })
    }
    const handleChange = (e) => {
        setSelected('')
        // onChange(e);
        if (initialData?.city?._id) {
            getTags()
        }
    }

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <Link to={`/puja-service/${props.data.value.category.slug}/${props.data.value.slug}/${initialData?.preference?.slug}`} className='searchbox'><div>{props.data.label}</div> </Link>
        </Option>
    );
    const loadOptions = (value, callback) => {

        setTimeout(() => {
            if (initialData?.city?._id) {
                getTags(value).then((res) => {
                    callback(res);
                })
            }
        }, 500);
    };
    // useEffect(()=>{
    //     if(initialData?.city?._id){
    //         getTags()
    //     }
    // },[initialData])
    return (
        <>
            <AsyncSelect
                // defaultOptions={options}
                placeholder="Search Anything"
                menuIsOpen={true}
                // cacheOptions={options}
                value={selected}
                getOptionLabel={e => e.label}
                getOptionValue={e => e._id}
                loadOptions={loadOptions}
                className="search_left"
                onBlur={(e) => onBlur(e)} onChange={(e) => handleChange(e)}
                components={{ Option: IconOption }}
            />
        </>
    )
}

export default SelectMultiple