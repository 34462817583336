import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../formik/errorMessage";
import ROOT_URL from '../../services/baseURL';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import { toast } from "react-toastify";

const Contact = () => {
    const formikContact = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, 'Name is too short!').max(15, 'Name is too short!').required('Name is required!'),
            email: Yup.string().email("Please enter valid email!").required('Email is required!'),
        }),

        onSubmit: values => {
            submitContact(values)
        }
    });
    
    const submitContact = (values) => {
        authAxios({
            method: "POST",
            url: `${ROOT_URL}/contact/addRequest`,
            data:values
        }).then((res)=>{
            toast.success(res.data.message);
            formikContact.resetForm()
        }).catch((error) => {
            common.error(error);
        })
    }
  return (
    <>
        <div className="tabContent ">
                <form onSubmit={formikContact.handleSubmit} autocomplete="off">
                  <div className="homenotiinputCon ">
                    <div className="row ">
                      <div className="col-lg-12 col-12">
                        <div className="homenoticallbackcon">Contact Us</div>
                        <div className="homeinptabcon">
                          <input type="text" className="form-control" placeholder="Enter Name"
                            {...formikContact.getFieldProps('name')} />
                          <ErrorMessage formik={formikContact} name="name" />

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div className="homeinptabcon">
                          <input type="text" className="form-control" placeholder="Enter Email Id"
                            {...formikContact.getFieldProps('email')} />
                          <ErrorMessage formik={formikContact} name="email" />

                        </div>
                      </div>
                    </div>

                    <div className="homeinfosubmitcon contactus">
                      <div className="homeinfosubmittext"><button type="submit">Submit</button></div>
                    </div>
                  </div>
                </form>

              </div>
    </>
  )
}

export default Contact