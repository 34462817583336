import React from 'react'

export const ErrorMessage = (props) => {
  const { formik, name } = props;
  return (
    <>
      {formik.touched?.[name] && formik.errors?.[name] ? (
        <div className="useformikerrormsg">{formik.errors?.[name]}</div>
      ) : null}
    </>
  )
}
