import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage } from "../components/formik/errorMessage";
import UnauthAxios from "../services/unauthAxios";
import common from "../services/common";
import { toast } from "react-toastify";

export default function Footer() {
  const [getSocial, setSocial] = useState()
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "FirstName is too short!")
        .max(15, "FirstName is too Long!")
        .required("First name is required!"),
      lastName: Yup.string()
        .min(2, " LastName is too short!")
        .max(15, "LastName is too short!")
        .required("Last name is required!"),
      email: Yup.string()
        .email("Please enter valid email!")
        .required("Email is required!"),
    }),

    onSubmit: (values) => {
      newsLetterapi(values);
    },
  });

  const newsLetterapi = (data) => {
    UnauthAxios({
      method: "POST",
      url: `/newsLetter/create`,
      data: data
    }).then((res) => {

      formik.resetForm();
      toast.success(res.data.message);
    }).catch((error) => {
      common.error(error);
    })
  }

  function getSocialLink() {
    UnauthAxios({
      method: "GET",
      url: `/pages/contactDetails`,
    }).then((res) => {
      setSocial(res.data.data)
    }).catch((error) => {
      common.error(error)
    });
  }

  useEffect(() => {
    getSocialLink()
  }, [])
  return (
    <>
      <div className="footerWrapper">
      <div className="container-fluid">
        <div className="footerwRP">
          <div className="row">
            <div className="col-lg-12 col-md-12 p-0">
              <div className="homefooterwrp">
              <div className="startupIndiaFooter">
                  <Link to='/'><img
                    className="img-fluid"
                    src="/assets/images/startup/footer-logo.png"
                    alt="images"
                    style={{height:'50px', objectFit:'contain'}}
                  />
                  </Link>
                  <Link to='/'><img
                  style={{margin: "25px 0", height: "70px"}}
                    className="img-fluid"
                    src="/assets/images/startup/DPIIT-footer.svg"
                    alt="images"
                  />
                  </Link>
                </div>
                {/* <div className="homeyogiImgcon">
                  <Link to='/'><img
                    className="img-fluid"
                    src="/assets/images/homeyogi.png"
                    alt="images"
                  />
                  </Link>
                </div> */}
                <div className="homefooterwrpcon">
                  <div className="footerliwrpcon">
                    <div className="homefooterbox1">
                      <div className="homefooterbox">
                        <ul>
                          <li>
                            <Link to="/the-samskara-story">The Samskara Story </Link>
                          </li>
                          <li>
                            <Link to="/advantage-samskara">Advantage Samskara </Link>
                          </li>
                          <li>
                            <Link to="">Downloadables</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="homefooterbox">
                        <ul>
                          <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/terms-and-conditions">Terms of Use</Link>
                          </li>
                          <li>
                            <Link to="/cancellation-policy">
                              Refunds & Cancellation
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="homefooterbox main">
                        <ul>
                          <li>
                            <a href="https://play.google.com/store/apps/details?id=com.samskara.customer" target="_blank">
                              <img
                                className="img-fluid"
                                src="/assets/images/homegooglePlay.svg"
                                alt="images"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="https://apps.apple.com/in/app/samskara/id6468908326" target="_blank">
                              <img
                                className="img-fluid"
                                src="/assets/images/homeAppStore.svg"
                                alt="images"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="homefooterbox2">
                      <div className="homefooterfollowcon">Let's Go Social</div>
                      <div className="homefootericoncon">
                        {getSocial?.section?.LinkedIn != "#" &&
                          <a href={`${getSocial?.section?.LinkedIn}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/linkedin.png"
                              alt="images"
                            />
                          </a>
                        }
                        {getSocial?.section?.Instagram != "#" &&
                          <a href={`${getSocial?.section?.Instagram}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/insta.png"
                              alt="images"
                            />
                          </a>
                        }
                        {getSocial?.section?.Facebook != "#" &&
                          <a href={`${getSocial?.section?.Facebook}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/facebook.png"
                              alt="images"
                            />
                          </a>
                        }
                        {getSocial?.section?.Youtube != "#" &&
                          <a href={`${getSocial?.section?.Youtube}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/youtube.png"
                              alt="images"
                              style={{ height: "28px" }}
                            />
                          </a>}
                        {getSocial?.section?.Google != "#" &&
                          <a href={`${getSocial?.section?.Google}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/Google.png"
                              alt="images"
                              style={{ height: "28px" }}
                            />
                          </a>}
                        {getSocial?.section?.Twitter != "#" &&
                          <a href={`${getSocial?.section?.Twitter}`} target={"_blank"}>
                            <img
                              className="img-fluid"
                              src="/assets/images/Twitter.png"
                              alt="images"
                              style={{ height: "28px" }}
                            />
                          </a>}
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="newsllerWrrp">
                    <div className="homefooterformcon">
                      <div className="footerinpconbox1">
                        <div className="footerinnerinpwrp">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="footernewspapertext">
                                <h3>NEWSLETTER</h3>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="footerinnerinp setpath">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  aria-label="First name"
                                  {...formik.getFieldProps("firstName")}
                                />
                                <ErrorMessage
                                  formik={formik}
                                  name="firstName"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="footerinnerinp">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  aria-label="Last name"
                                  {...formik.getFieldProps("lastName")}
                                />
                                <ErrorMessage formik={formik} name="lastName" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-12">
                              <div className="footerinpconbox2">
                                <div className="footerinnerinp">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Email ID"
                                    {...formik.getFieldProps("email")}
                                  />
                                  <ErrorMessage formik={formik} name="email" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="footerinpconbox3">
                        <button type="submit">Subscribe</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
