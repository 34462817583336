import React from 'react'

export default function SamskaraLoader() {
  return (
    <div className="">
      <div className="pageLoadr">
        <img width={300} height={300} className="img-fluid" src="/assets/images/samskara.gif" alt="image" />
      </div>
    </div>
  )
}
