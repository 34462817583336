import {v4} from "uuid";
import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from "reactjs-localstorage";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [isLogin, setLogin] = useState(false);
  const [modalCountry, setModalCountry] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [initialData, setInitialData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [language, setLanguage] = useState('');
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([])
  const [eventPrice, setEventPrice] = useState(0)
  const [signCartData, setSignCartData] = useState({});
  const [reUrl, setReUrl] = useState('');
  const [cartnotification, setCartnotification] = useState(0);
  const [launchPopupViewed, setLaunchPopupViewed] = useState(false);


  const reset = () => {
    console.log("reset");
    setLogin(false);
    localStorage.removeItem('token')
    localStorage.removeItem('signCartData')
    localStorage.removeItem('reUrl')
    // localStorage.removeItem('initialData')
    localStorage.removeItem('eventPrice')
    localStorage.removeItem('eventData')
    navigate('/signin')
  }

  useEffect(() => {
    if(!reactLocalStorage.get('guestId')){
      reactLocalStorage.set('guestId', v4());
    }
    let authLocal = reactLocalStorage.get('token');
    setLogin(authLocal);
    const today = new Date();
    const dateString = today.toISOString().slice(0, 10); // Get YY
    const localStorageString = localStorage.getItem('bannerView');
    if(dateString === localStorageString) {
      setLaunchPopupViewed(true);
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        isLogin, setLogin,
        signCartData, setSignCartData,
        reUrl, setReUrl,
        reset,
        userInfo, setUserInfo,
        modalCountry, setModalCountry,
        initialData, setInitialData,
        profileData, setProfileData,
        language, setLanguage,
        eventData, setEventData,
        eventPrice, setEventPrice,
        cartnotification, setCartnotification,
        launchPopupViewed, setLaunchPopupViewed
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
