import { Select } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import UnauthAxios from '../../services/unauthAxios';

function Translator() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en', includedLanguages: 'en,hi,te,or,ml,gu,ta,bn,mr',
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div className="tabContent ">
      <form >
        <div className="homenotiinputCon ">
          <div className="row ">
            <div className="col-lg-12 col-12">
              <div className="homenoticallbackcon">Translate Page to </div>

              <div className="homeinptabcon">

                <div id="google_translate_element"></div>
                {/* <input type="text" className="form-control" placeholder="Enter Name" /> */}


              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 col-12">
              <div className="homeinptabcon">
                <input type="text" className="form-control" placeholder="Enter Email Id"
                />

              </div>
            </div>
          </div> */}

          {/* <div className="homeinfosubmitcon contactus">
            <div className="homeinfosubmittext"><button type="submit">Submit</button></div>
          </div> */}
        </div>
      </form>

    </div>
  )
}

export default Translator