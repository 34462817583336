import React from 'react'
import { Oval } from 'react-loader-spinner'
import SamskaraLoader from '../loader/samskaraLoader'
export default function Loader() {
  return (
    <div className="pageloaderWrp">
      <div className="pageLoadr">
        {/* <Oval color="#1160c1" height={70} width={70} /> */}
        <SamskaraLoader/>
      </div>
    </div>
  )
}
