import React, { useState } from 'react'
import { ErrorMessage } from '../formik/errorMessage';
import common from '../../services/common';
import { toast } from 'react-toastify';
import SelectMultiple from './selectMultiple';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Search = () => {
  const [tagsData, settagsData] = useState([])

  //formik
  let initialValues = {
    search: '',
  }

  let validationSchema = Yup.object().required("Enter String");

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {

    },
  });

  const selectHandler = (e) => {
    let tData = [...tagsData];
    let filr = tagsData.filter((data) => data.value == e.value);
    if (filr.length != 1) {
      tData.push(e)
    } else {
      toast.error("You have already selected")
    }
    settagsData(tData)
    formik.setFieldValue('tags', tData?.map((data) => data.value) || "")
  }

  function removeHandlerTags(i) {
    const tData = [...tagsData];
    tData.splice(i, 1);
    settagsData(tData);
    formik.setFieldValue('tags', tData?.map((data) => data.value) || "")
  }

  const selectBlur = (e) => {
    formik.setFieldTouched('tags', true)
  }

  return (
    <>
      <div className="tabContent inputfield ">
        <div className="homenotiinputwrp  ">
          {/* <input type="text" placeholder="Search Anything..." /> */}
          <form onSubmit={formik.handleSubmit} autocomplete="off">
            <SelectMultiple
              value={tagsData.name}
              onBlur={(e) => selectBlur(e)}
              onChange={(e) => selectHandler(e)}
              remove={(i) => removeHandlerTags(i)}
            />
            <ErrorMessage formik={formik} name="tags" />
          </form>
        </div>
      </div>
    </>
  )
}

export default Search