import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../../context/context';
import ROOT_URL from '../../services/baseURL';
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../formik/errorMessage';

const CountryModal = () => {
    const { modalCountry, setModalCountry, initialData, setInitialData } = useContext(UserContext);
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [preference, setPreference] = useState([])



    let initialValues = {
        country: {},
        city: {},
        preference: {},
    }


    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (Object.keys(values?.country).length != 0 && Object.keys(values?.city).length != 0 && Object.keys(values?.preference).length != 0) {
                let jsonValues = JSON.stringify(values);
                localStorage.setItem('initialData', jsonValues)
                setInitialData(values)
                setModalCountry(false)
            }

            //   editAdmin(values);
        },
    });

    const getCountry = () => {
        UnauthAxios({
            method: "GET",
            url: `${ROOT_URL}/country/getAll`,
        }).then((res) => {
            let data = res.data.data;
            formik.setFieldValue('country', data[0])
            setCountryList(data);
            getCity(data[0])
        }).catch((error) => {
            common.error(error)
        });
    }
    const getCity = (countryData) => {
        UnauthAxios({
            method: "GET",
            url: `${ROOT_URL}/city/getAll/${countryData?._id}`,
        }).then((res) => {
            let data = res.data.data;
            if (countryData?.name?.toUpperCase() == "INDIA") {
                let priorityData
                data = data?.filter((value) => {
                    if (value?.name?.toLowerCase() == "delhi-ncr") {
                        priorityData = value
                    } else {
                        return value
                    }
                })
                data = [priorityData, ...data]

            }

            setCityList(data);

            if (initialData?.country?._id == countryData?._id) {
                formik.setFieldValue("city", initialData?.city)
            } else {
                formik.setFieldValue("city", data[0])
            }


        }).catch((error) => {
            common.error(error)
        });
    }

    const getPreference = () => {
        UnauthAxios({
            method: "GET",
            url: `${ROOT_URL}/panditPreference/getAll`,
        }).then((res) => {
            let data = res.data.data;
            let present = data?.find(x => x?.name?.toLowerCase() == "hindi")

            if (present?.name) {
                let priorityData
                data = data?.filter((value) => {
                    if (value?.name?.toLowerCase() == "hindi") {
                        priorityData = value
                    } else {
                        return value
                    }
                })
                data = [priorityData, ...data]
            }

            setPreference(data);
            formik.setFieldValue('preference', data[0])
        }).catch((error) => {
            common.error(error)
        });
    }

    useEffect(() => {
        if (modalCountry && initialData.country) {
            getCity(initialData.country)
            formik.setValues(initialData)
        }
    }, [modalCountry])

    useEffect(() => {
        getCountry()
        getPreference();
    }, [])


    const countryHandle = (obj) => {
        formik.setFieldValue('country', obj)
        getCity(obj)


    }

    const cityHandle = (obj) => {
        if(obj.comingSoon === false){
            formik.setFieldValue('city', obj)
        }
    }
    const preferenceHandle = (obj) => {
        formik.setFieldValue('preference', obj)
    }

    useEffect(() => {
        let localData = localStorage.getItem('initialData');
        if (localData) {
            let parseData = JSON.parse(localData)
            if(!parseData?.preference?.slug) return setModalCountry(true);
            setInitialData(parseData)
        } else {
            setModalCountry(true)
        }
    }, [])

    const handleModalClose = () => {
        if (Object.keys(initialData)?.length == 0) {

            formik.handleSubmit()
        } else {
            setModalCountry(false)
        }


    }

    return (
        <>
            <Modal show={modalCountry} onHide={() => setModalCountry(false)} size="lg" className="modalCountrySelect" backdrop="static" keyboard={false} centered >
                <Modal.Body>
                    <div className="selectcountryWRp">
                        <form onSubmit={formik.handleSubmit} >
                            <div className="selectcountryWRpcon">

                                <div className="selectcoutrycon mode">
                                    <span className='modal_close' onClick={handleModalClose}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                    <div className="selectcountryheadtext">

                                        <h2>Select Country</h2>

                                    </div>
                                    <div className="selectradiocon">
                                        {countryList.map((d, k) => (
                                            <div className="ganeshpoojaradioPaycon selectcountry" key={k + "countryList"}>
                                                <label>
                                                    <input className="form-check-input" type="radio" name="country" onChange={() => countryHandle(d)}
                                                        defaultChecked={initialData?.country?._id ? (initialData?.country?._id == d._id ? true : false) : (k == 0 ? true : false)} />
                                                    <span>{d.name} </span>
                                                </label>

                                            </div>
                                        ))}
                                    </div>


                                    <div className="selectcountryimgwrp">
                                        {cityList.map((d, k) => (
                                            <label className="selectcountryimgcon" key={'city' + k }>
                                                <input type="radio" name="city" onChange={() => cityHandle(d)}
                                                    value={formik?.values?.city?._id} checked={formik?.values?.city?._id == d?._id} />
                                                <div className="selectcountryimgbox">
                                                    <div style={{position: 'relative'}}>
                                                        <img className={"img-fluid" + (d.comingSoon?' city-disable-img':'')}src={d?.image?.path ? d?.image?.path : '/assets/images/jaipur.png'} alt="images" />
                                                        {d.comingSoon && <span className='city-comingSoon'>Coming Soon</span>}
                                                    </div>
                                                    <div className="selectimgtextcon">{d?.name}</div>
                                                </div>
                                            </label>
                                        ))}
                                    </div>

                                    <p className='delhincr-note'>* Delhi-NCR includes Delhi, Noida, Ghaziabad, Faridabad & Gurugram</p>
                                </div>
                                <div className="selectcoutrycon">
                                    <div className="selectcountryheadtext">
                                        <h2>Select Pandit Preference</h2>
                                    </div>
                                    <div className="selectcountryimgwrp">
                                        {preference.map((d, k) => (
                                            <label className="selectcountryimgcon" key={'lang' + k}>
                                                <input type="radio" name="language" onChange={() => preferenceHandle(d)} defaultChecked={initialData?.preference?._id ? (initialData?.preference?._id == d._id ? true : false) : (k == 0 ? true : false)}/>
                                                <div className="selectcountryimgbox">
                                                    <div className='lanqesbox'>{d.nativeName}</div>
                                                    <div className="selectimgtextcon">{d.name}</div>
                                                </div>
                                            </label>
                                        ))}
                                    </div>


                                </div>
                                <div className="submitBtn"> <button type='submit' className='btn btn-lg btn-thm-1 br-25' disabled={cityList?.length == 0}>Next</button> </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default CountryModal