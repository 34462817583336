import React from 'react'
const styles = {
  wrapper: {
    textAlign: "center",
    minHeight: "100vh",
    padding: "30px 0",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",

  }
}
const NotFound = () => {
  return (
    <div className={`no-search-result`} style={styles.wrapper}>
        <img src="/assets/images/notfound.png" className="img-fluid" />
    </div>
  )
}
const NotificationNotFound = () => {
  return (
    <div className="no-search-result notification">
        <img src="/assets/images/notfound.png" className="img-fluid" />
    </div>
  )
}

export {NotificationNotFound}
export default NotFound